import React, { Component } from 'react';
import { Card } from '@blueprintjs/core';
import {
  ButtonContainer,
  DialogContainer,
  publishPayload,
  ErrorContainer
} from './PublishCommon';
import InputTextArea from '../common/InputTextArea';
import './Publish.css';
import SimpleReactValidator from 'simple-react-validator';

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      disablePublish: false,
      error: '',
      parsedPayload: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleSubmit() {
    const { common } = this.props;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (common.customPayload === '') {
      return;
    }
    var payload = '',
      error = '';
    try {
      payload = JSON.parse(common.customPayload);
    } catch (e) {
      var d = new Date();
      this.props.onResultChange(
        '[' +
          d.toLocaleTimeString() +
          '] Parse error, please check the payload.'
      );
      payload = 'Parse error, please check the payload.';
      error = 'Parse Error: Please check the payload.';
      this.setState({
        error: error,
        parsedPayload: payload
      });
      return;
    }

    this.setState({
      disablePublish: true,
      error: '',
      parsedPayload: JSON.stringify(payload, null, '\t')
    });

    setTimeout(() => {
      this.setState({ disablePublish: false });
    }, 1000);

    const param = {
      payload: payload
    };

    var store = {
      customPayload: common.customPayload,
      selectedTabId: common.selectedTabId
    };

    publishPayload(param, store, this.props);
  }

  render() {
    const { common, onChange } = this.props;
    const { disablePublish, parsedPayload, error } = this.state;
    this.validator.purgeFields();

    return (
      <div>
        <InputTextArea
          id="customPayload"
          name="customPayload"
          displayName="Custom Payload"
          value={common.customPayload}
          onChange={onChange}
          required={true}
          validator={this.validator}
        />
        <ButtonContainer
          onSubmit={this.handleSubmit}
          disablePublish={disablePublish}
          onOpen={this.handleOpen}
        />
        <DialogContainer
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          value={parsedPayload}
        />
        <ErrorContainer error={error} />
      </div>
    );
  }
}

export default class CustomPanel extends Component {
  render() {
    return (
      <Card id={this.props.id} className="tabPanel borderRadius" elevation={1}>
        <FormContainer {...this.props} />
      </Card>
    );
  }
}
