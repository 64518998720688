import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../utils/default-config';
import { ErrorHandler } from '../components/common/Common';

export default function publish(param) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  var headers = {
    Authorization: 'Bearer ' + accessToken
  };

  return axios({
    url: config.publishURL + '/tool/publish',
    method: 'POST',
    headers: headers,
    data: param.payload
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}
