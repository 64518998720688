import axios from 'axios';
import Cookies from 'universal-cookie';
import { UUIDGenerator } from '../utils/helper';
import config from '../utils/default-config';
import { ErrorHandler } from '../components/common/Common';

export function submitRequest(param) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  var headers = {
    Authorization: 'Bearer ' + accessToken
  };

  var payload = {
    identifierValue: param.identifierValue,
    identifierType: param.identifierType,
    appId: param.appId,
    providerType: param.providerType,
    requestId: UUIDGenerator()
  };

  return axios({
    url: config.apiURL + '/tool/search',
    method: 'POST',
    headers: headers,
    data: payload
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}
