import React, { Component } from 'react';
import { submitRequest } from '../../api';
import InputField from '../common/InputField';
import {
  Button,
  HTMLSelect,
  FormGroup,
  MenuItem,
  Intent,
  H4
} from '@blueprintjs/core';
import Constants from '../../utils/constants';
import './Search.css';
import SimpleReactValidator from 'simple-react-validator';
import SelectBox from '../common/SelectBox';
import { getAllApplications } from '../../api/appApi';

const searchListOptions = [
  { label: 'Push Token', value: 'endpoint' },
  { label: 'Association', value: 'association' },
  { label: 'BCookie', value: 'bcookie' },
  { label: 'ACookie', value: 'acookie' },
  { label: 'BID', value: 'bid' },
  { label: 'SID', value: 'sid' },
  {
    label: 'IDFV (iOS) / Android_Id (Android)',
    value: 'device_id'
  },
  {
    label: 'IDFA (iOS) / GPAdvertisingId (Android)',
    value: 'advertising_id'
  },
  { label: 'Registration Id (Hashed)', value: 'hashed_registration_id' },
  { label: 'Registration Id (UnHashed)', value: 'registration_id' }
];

class SearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identifierType: 'endpoint',
      app: '',
      identifierValue: '',
      associationType: '',
      items: []
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setupLink = this.setupLink.bind(this);

    // functions related to select box
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleAppSelectChange = this.handleAppSelectChange.bind(this);
    this.filterApps = this.filterApps.bind(this);

    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  setupLink() {
    const {
      identifierType,
      associationType,
      appId,
      appDisplayName,
      identifierValue
    } = this.props.params;
    if (identifierType && appId && appDisplayName && identifierValue) {
      this.setState(
        {
          identifierType: identifierType,
          associationType: associationType || '',
          app: { appId: appId, displayName: appDisplayName },
          identifierValue: identifierValue
        },
        this.handleSubmit
      );
    }
  }

  componentDidMount() {
    getAllApplications()
      .then(applications => {
        applications.sort((a, b) => (a.appId > b.appId ? 1 : -1));
        this.setState(
          {
            items: applications
          },
          this.setupLink
        );
      })
      .catch(error => {
        this.props.showAlert(error.toString(), Intent.DANGER);
      });
  }

  handleResultChange(error, msgType, searchResult, callback) {
    this.props.onChange(error, msgType, searchResult, [], callback);
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  handleSelectChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit() {
    const {
      identifierType,
      identifierValue,
      app,
      associationType
    } = this.state;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    var value = identifierValue.trim();
    if (identifierType === 'association') {
      value = associationType.trim() + '|' + value;
    }

    const param = {
      identifierValue: value,
      identifierType: identifierType,
      appId: app.appId,
      providerType: app.providerType
    };

    this.handleResultChange(undefined, 'searching', [], () => {
      submitRequest(param)
        .then(data => {
          try {
            if (data && data[Constants.DDB]['registrations'].length !== 0) {
              this.handleResultChange(undefined, 'data', data);
            } else {
              this.handleResultChange(
                'No Record found. Please check the identifier value or the application id.',
                'generalError',
                []
              );
            }
          } catch (error) {
            this.handleResultChange('Server Error.', 'generalError', []);
          }
        })
        .catch(error => {
          this.props.showAlert(error.toString(), Intent.DANGER);
          try {
            var errCode = error.response.data.errorCode
              ? error.response.data.errorCode
              : 'Unknown';
            this.handleResultChange(
              'Error code: ' + errCode.toString(),
              'errorCode',
              []
            );
          } catch (error) {
            this.handleResultChange(
              'Unknown Error. Check Internet connection first.',
              'generalError',
              []
            );
          }
        });
    });
    if (associationType) {
      window.history.pushState(
        {},
        '',
        '/search' +
          '/' +
          identifierType +
          '/' +
          associationType +
          '/' +
          app.appId +
          '/' +
          app.displayName +
          '/' +
          identifierValue
      );
    } else {
      window.history.pushState(
        {},
        '',
        '/search' +
          '/' +
          identifierType +
          '/' +
          app.appId +
          '/' +
          app.displayName +
          '/' +
          identifierValue
      );
    }
  }

  handleAppSelectChange(app) {
    this.setState({
      app
    });
  }

  renderAppSelect = (app, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const keyStr = app.appId + '_' + app.providerType;
    const label = app.displayName ? '(' + app.displayName + ')' : '';
    return (
      <MenuItem
        active={modifiers.active}
        key={keyStr}
        label={label}
        onClick={handleClick}
        text={app.appId}
      />
    );
  };

  filterApps = (query, app) => {
    const appId = `${app.appId}`;
    const displayName = `${app.displayName}`;
    return (
      appId.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  };

  render() {
    const {
      identifierValue,
      app,
      items,
      associationType,
      identifierType
    } = this.state;
    this.validator.purgeFields();
    return (
      <div className="bp3-card bp3-elevation-2 full-width">
        <H4>Records Search</H4>
        <div className="bp3-card bp3-elevation-2 full-width">
          <div className="display-flex">
            <FormGroup
              inline={false}
              label="Identifier Type"
              labelFor="text-input"
              labelInfo="(required)"
              className="margin-right"
            >
              <HTMLSelect
                name="identifierType"
                options={searchListOptions}
                onChange={this.handleSelectChange}
                fill={true}
                value={identifierType}
              />
            </FormGroup>
            {identifierType === 'association' && (
              <div className="margin-right">
                <InputField
                  name="associationType"
                  value={associationType}
                  onChange={this.handleChange}
                  displayName="Association Type"
                  id="associationType"
                  placeholder="e.g. guid, mfin, fanid"
                  validator={this.validator}
                />
              </div>
            )}
            <FormGroup
              inline={false}
              label="Application"
              labelFor="text-input"
              labelInfo="(required)"
              className="margin-right"
            >
              <SelectBox
                items={items}
                onItemSelect={this.handleAppSelectChange}
                itemRenderer={this.renderAppSelect}
                selectedItem={app}
                name="displayName"
                label="appId"
                defaultLabel="Select Application"
                itemPredicate={this.filterApps}
              />
              {this.validator.message('application', app, 'required', {
                className: 'note-alert-color'
              })}
            </FormGroup>
            <div className="inputField">
              <InputField
                name="identifierValue"
                value={identifierValue}
                onChange={this.handleChange}
                displayName="Identifier Value"
                id="identifierValue"
                validator={this.validator}
              />
            </div>
          </div>
          <Button
            type="Submit"
            intent="success"
            text="Search"
            className="margin-auto"
            onClick={this.handleSubmit}
          />
        </div>
      </div>
    );
  }
}

class SearchFormContainer extends Component {
  render() {
    return <SearchForm {...this.props} />;
  }
}

export default SearchFormContainer;
