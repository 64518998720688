import axios from 'axios';
import Cookies from 'universal-cookie';
import { UUIDGenerator } from "../utils/helper";
import config from '../utils/default-config';
import { ErrorHandler } from '../components/common/Common';

export function submitCountRequest(param) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  var headers = {
    Authorization: 'Bearer ' + accessToken
  };

  var payload = {
    requestId: UUIDGenerator(),
    appIds: param.appIds,
    tag: param.tag
  };

  return axios({
    url: config.apiURL + '/tool/devicecount',
    method: 'POST',
    headers: headers,
    data: payload
  })
    .then(response => {
      console.log(response);
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}