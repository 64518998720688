import React, { Component } from 'react';
import { FormGroup } from '@blueprintjs/core';

export default class InputTextArea extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  render() {
    const display = this.props.displayName;
    const { name, id, value, placeholder, required, validator } = this.props;
    var mutemsg = <div />;
    if (this.props.mutemsg) {
      mutemsg = <span className="bp3-text-muted">({this.props.mutemsg})</span>;
    }

    return (
      <FormGroup>
        <label className="bp3-label .modifier">
          {display} {mutemsg}
        </label>
        <textarea
          id={id}
          className="textarea"
          name={name}
          value={value}
          onChange={this.handleChange}
          placeholder={placeholder}
          required={required}
        />
        {validator &&
          validator.message(id, value, 'required', {
            className: 'note-alert-color'
          })}
      </FormGroup>
    );
  }
}
