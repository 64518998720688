import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  Divider,
  ControlGroup,
  MenuItem,
  Intent
} from '@blueprintjs/core';
import TargetPanel from './PublishTargetPanel';
import TagPanel from './PublishTagPanel';
import CustomPanel from './PublishCustomPanel';
import PublishResultContainer from './PublishResultContainer';
import './Publish.css';
import config from '../../utils/default-config';
import Cookies from 'universal-cookie';
import { getPermittedApplications } from '../../api/appApi';
import Constants from '../../utils/constants';

export default (class Publish extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTabId: 'target',
      loading: false,
      notificationId: '',
      identifierType: 'Push Token',
      payloadType: Constants.MESSAGE_TITLE_BODY,
      isCustomPayloadSelected: false,
      identifierValue: '',
      title: '',
      body: '',
      tag: '',
      customPayload: '',
      customAppPayload: '',
      result: [],
      selected: [],
      items: []
    };
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.loading = this.loading.bind(this);
    this.handleIsCustomPayloadSelectedChange = this.handleIsCustomPayloadSelectedChange.bind(
      this
    );
    // all functions related to multiselect
    this.renderAppSelect = this.renderAppSelect.bind(this);
    this.renderTag = this.renderTag.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.selectApp = this.selectApp.bind(this);
    this.deselectSelected = this.deselectSelected.bind(this);
    this.filterApps = this.filterApps.bind(this);
    this.handleTagRemove = this.handleTagRemove.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentDidMount() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');
    if (!accessToken) window.location.replace(config.appURL);
    var pushHistory = JSON.parse(localStorage.getItem('pushHistory'));
    if (!pushHistory) pushHistory = [];
    this.setState({ result: pushHistory });
    getPermittedApplications()
      .then(applications => {
        applications.sort((a, b) => (a.appId > b.appId ? 1 : -1));
        this.setState({
          items: applications
        });
      })
      .catch(error => {
        this.props.showAlert(error.toString(), Intent.DANGER);
      });
  }

  handleResultChange(reloadNotificationId) {
    this.setState({
      result: JSON.parse(localStorage.getItem('pushHistory')),
      loading: false,
      notificationId: reloadNotificationId ? '' : this.state.notificationId
    });
  }

  handleEdit(value) {
    value['notificationId'] = '';
    this.setState(value);
  }

  handleChange(name, value) {
    if (name === Constants.PAYLOAD_TYPE) {
      this.handleIsCustomPayloadSelectedChange(value);
    }
    this.setState({ [name]: value });
  }

  handleIsCustomPayloadSelectedChange(value) {
    this.setState({
      isCustomPayloadSelected: value === Constants.CUSTOM_APP_PAYLOAD
    });
  }

  loading(callback) {
    this.setState({ loading: true }, callback);
  }

  handleTabChange(tab) {
    this.setState({ selectedTabId: tab });
  }

  renderAppSelect = (app, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const keyStr = app.appId + '_' + app.providerType;
    const label = app.displayName ? '(' + app.displayName + ')' : '';
    return (
      <MenuItem
        active={modifiers.active}
        icon={this.isSelected(app) ? 'tick' : 'blank'}
        key={keyStr}
        label={label}
        onClick={handleClick}
        text={app.appId}
      />
    );
  };

  renderTag = app => app;

  handleSelect = app => {
    if (!this.isSelected(app)) {
      this.selectApp(app);
    } else {
      this.deselectSelected(app);
    }
  };

  isSelected(app) {
    return this.state.selected.indexOf(app) !== -1;
  }

  selectApp(app) {
    var nextSelected = this.state.selected;
    nextSelected.push(app);
    this.setState({
      selected: nextSelected
    });
  }

  deselectSelected(deselectedApp) {
    const selected = this.state.selected.filter(
      app =>
        deselectedApp.appId !== app.appId ||
        deselectedApp.providerType !== app.providerType
    );
    this.setState({
      selected: selected
    });
  }

  filterApps = (query, app) => {
    const appId = `${app.appId}`;
    const displayName = `${app.displayName}`;
    return (
      appId.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  };

  handleTagRemove = (_tag, index) => {
    const selected = this.state.selected.filter(app => _tag !== app.appId);
    this.setState({
      selected: selected
    });
  };

  handleClear = () =>
    this.setState({
      selected: []
    });

  render() {
    const common = this.state;
    const { selectedTabId, items, selected } = this.state;
    return (
      <ControlGroup>
        <Tabs
          className="row"
          animate={true}
          id="TabsExample"
          key="horizontal"
          vertical={true}
          large={true}
          selectedTabId={selectedTabId}
          onChange={this.handleTabChange}
        >
          <Tab
            className="tabContainer"
            id="target"
            title="Target"
            panel={
              <TargetPanel
                id="targetPanel"
                onResultChange={this.handleResultChange}
                loading={this.loading}
                onChange={this.handleChange}
                common={common}
                items={items}
                itemRenderer={this.renderAppSelect}
                tagRenderer={this.renderTag}
                itemPredicate={this.filterApps}
                onItemSelect={this.handleSelect}
                handleTagRemove={this.handleTagRemove}
                handleClear={this.handleClear}
                selected={selected}
              />
            }
          />
          <Divider />
          <Tab
            className="tabContainer"
            id="tag"
            title="Tag"
            disabled={config.isProduction}
            panel={
              <TagPanel
                id="tagPanel"
                onResultChange={this.handleResultChange}
                onChange={this.handleChange}
                loading={this.loading}
                common={common}
                items={items}
                itemRenderer={this.renderAppSelect}
                tagRenderer={this.renderTag}
                itemPredicate={this.filterApps}
                onItemSelect={this.handleSelect}
                handleTagRemove={this.handleTagRemove}
                handleClear={this.handleClear}
                selected={selected}
              />
            }
          />
          <Divider />
          <Tab
            className="tabContainer"
            id="custom"
            title="Custom"
            disabled={config.isProduction}
            panel={
              <CustomPanel
                id="customPanel"
                onResultChange={this.handleResultChange}
                loading={this.loading}
                onChange={this.handleChange}
                common={common}
              />
            }
          />
          <Tabs.Expander />
        </Tabs>
        <PublishResultContainer
          results={this.state.result}
          loading={this.state.loading}
          handleEdit={this.handleEdit}
          handleResultChange={this.handleResultChange}
        />
      </ControlGroup>
    );
  }
});
