import React, { Component } from 'react';
import {
  ControlGroup,
  Button,
  HTMLSelect,
  Dialog,
  ButtonGroup,
  FormGroup,
  InputGroup
} from '@blueprintjs/core';
import InputField from '../common/InputField';
import InputTextArea from '../common/InputTextArea';
import publish from '../../api/publish';
import './Publish.css';
import { UUIDGenerator } from '../../utils/helper';
import Constants from '../../utils/constants';

class NotificationForm extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleGenerate = this.handleGenerate.bind(this);
  }
  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  handleGenerate() {
    const uuid = UUIDGenerator();
    this.props.onChange(this.props.name, uuid);
  }

  render() {
    const { value, displayName, mutemsg, validator } = this.props;
    var message = <div />;
    if (mutemsg) {
      message = <span className="bp3-text-muted">({mutemsg})</span>;
    }
    return (
      <FormGroup>
        <label className="bp3-label .modifier">
          {displayName} <span className="bp3-text-muted">{message}</span>
        </label>
        <ControlGroup fill={true}>
          <InputGroup
            id="notificationInput"
            value={value}
            onChange={this.handleChange}
            required={true}
          />
          <Button
            id="generateButton"
            intent="primary"
            text="Generate"
            onClick={this.handleGenerate}
          />
        </ControlGroup>
        {validator &&
          validator.message('notificationId', value, 'required', {
            className: 'note-alert-color'
          })}
      </FormGroup>
    );
  }
}

class IdentifierType extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  render() {
    const { value, displayName, name, mutemsg } = this.props;
    var message = <div />;
    if (mutemsg) {
      message = <span className="bp3-text-muted">({mutemsg})</span>;
    }
    return (
      <FormGroup>
        <label className="bp3-label .modifier">
          {displayName} {message}
        </label>
        <HTMLSelect
          name={name}
          value={value}
          options={['Push Token', 'Association Value', 'bcookie']}
          onChange={this.handleChange}
          fill={true}
        />
      </FormGroup>
    );
  }
}

class PayloadType extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.props.onChange(this.props.name, e.target.value);
  }

  render() {
    const { value, displayName, name, mutemsg } = this.props;
    var message = <div />;
    if (mutemsg) {
      message = <span className="bp3-text-muted">({mutemsg})</span>;
    }
    return (
      <FormGroup>
        <label className="bp3-label .modifier">
          {displayName} {message}
        </label>
        <HTMLSelect
          name={name}
          value={value}
          options={[Constants.MESSAGE_TITLE_BODY, Constants.CUSTOM_APP_PAYLOAD]}
          onChange={this.handleChange}
          fill={true}
        />
      </FormGroup>
    );
  }
}

class DialogContainer extends Component {
  render() {
    return (
      <Dialog
        title="Payload"
        tabIndex={-1}
        icon="info-sign"
        onClose={this.props.onClose}
        isOpen={this.props.isOpen}
      >
        <textarea
          id="modal"
          className="bp3-input .modifier"
          dir="auto"
          value={this.props.value}
          readOnly={true}
        />
      </Dialog>
    );
  }
}

class MessageContainer extends Component {
  render() {
    const { title, onChange, body, validator } = this.props;
    return (
      <div>
        <InputField
          id="messageTitle"
          name="title"
          displayName="Message Title"
          value={title}
          onChange={onChange}
          required={true}
          validator={validator}
        />
        <InputTextArea
          name="body"
          displayName="Message Body"
          mutemsg="optional"
          value={body}
          onChange={onChange}
          id="messageBody"
        />
      </div>
    );
  }
}

class ButtonContainer extends Component {
  render() {
    const { onSubmit, onOpen, disablePublish } = this.props;
    return (
      <ButtonGroup fill={true}>
        <Button
          large={true}
          minimal={true}
          icon="upload"
          text="Publish"
          type="Submit"
          onClick={onSubmit}
          disabled={disablePublish}
        />
        <Button
          large={true}
          minimal={true}
          icon="document"
          text="Show Payload"
          onClick={onOpen}
        />
      </ButtonGroup>
    );
  }
}

class ErrorContainer extends Component {
  render() {
    const { error } = this.props;
    return (
      <div>
        <br />
        <span className="note-alert-color">{error}</span>
      </div>
    );
  }
}

function publishPayload(param, store, props) {
  var date = new Date().toLocaleTimeString();
  var res = '';
  var reloadNotificationId = true;
  props.loading(() => {
    publish(param)
      .then(data => {
        res = `[${date}] ${JSON.stringify(data)}.`;
      })
      .catch(error => {
        reloadNotificationId = false;
        try {
          res = `[${date}] ${error.toString()}. ${JSON.stringify(
            error.response.data,
            null,
            '\t'
          )}.`;
        } catch (err) {
          res = `[${date}] ${error.toString()}`;
        }
      })
      .then(() => {
        store['res'] = res;
        storeHistory(store);
      })
      .then(() => props.onResultChange(reloadNotificationId));
  });
}

function storeHistory(store) {
  var pushHistory = JSON.parse(localStorage.getItem('pushHistory'));
  if (!pushHistory) pushHistory = [];
  pushHistory.push(store);
  localStorage.setItem('pushHistory', JSON.stringify(pushHistory));
}

function payloadTemplate(params) {
  const typeMap = {
    'Push Token': 'endpoint',
    'Association Value': 'associationValue',
    bcookie: 'bcookie'
  };

  const identifierValue = params.identifierValue.split(',');
  const apps = params.selected;
  const appIds = apps.map(app => app.appId);
  const identifierType = typeMap[params.identifierType];

  identifierValue.forEach(function(item, index) {
    this[index] = item.trim();
  }, identifierValue);

  appIds.forEach(function(item, index) {
    this[index] = item.trim();
  }, appIds);

  function getPayload(params) {
    var payload = {};
    if (params.isCustomPayloadSelected) {
      return params.customAppPayloadJson;
    }
    params.selected.forEach(app => {
      if (app.providerType === 'CHROME' || app.providerType === 'WEBPUSH') {
        payload['browser'] = {
          raw: {
            data: {
              title: params.title,
              summary: params.body
            }
          }
        };
      } else if (app.providerType === 'FCM' || app.providerType === 'ADM') {
        payload['fcm'] = {
          raw: {
            notification: {
              title: params.title,
              body: params.body
            }
          }
        };
      } else if (app.providerType === 'APNS') {
        payload['apns'] = {
          raw: {
            aps: {
              alert: {
                title: params.title,
                body: params.body
              }
            }
          }
        };
      } else {
        payload = {
          fcm: {
            raw: {
              notification: {
                title: params.title,
                body: params.body
              }
            }
          },
          apns: {
            raw: {
              aps: {
                alert: {
                  title: params.title,
                  body: params.body
                }
              }
            }
          }
        };
      }
    });

    return payload;
  }

  var template = {
    notificationId: params.notificationId,
    payload: getPayload(params),
    params: {
      appIds: appIds
    },
    timeToLive: 10,
    dryRun: false
  };

  return { template, identifierType, identifierValue };
}

export {
  DialogContainer,
  MessageContainer,
  NotificationForm,
  IdentifierType,
  ButtonContainer,
  publishPayload,
  payloadTemplate,
  ErrorContainer,
  PayloadType
};
