import React, { Component } from 'react';
import './Login.css';
import config from '../../utils/default-config';
import { Spinner, Intent } from '@blueprintjs/core';
import { getToken } from '../../api';
import Cookies from 'universal-cookie';

export default (class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: null,
      state: null,
      message: 'Validating',
      size: Spinner.SIZE_LARGE,
      intent: Intent.PRIMARY
    };
    this.authenticateUser = this.authenticateUser.bind(this);
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const code = params.get('code');
    const state = params.get('state');
    this.setState({
      code: code,
      state: state
    });
    var localState = localStorage.getItem('state');
    if (localState && state && code && localState === state) {
      this.authenticateUser(code);
    } else {
      window.location.replace(config.appURL);
    }
  }

  authenticateUser(code) {
    getToken(code).then(() => {
      const cookie = new Cookies();
      const accessToken = cookie.get('accessToken');
      if (accessToken) {
        window.location.replace(config.dashboardURL);
      } else {
        window.location.replace(config.appURL);
      }
    });
  }

  render() {
    const { message, size, intent } = this.state;
    return (
      <div className="loginTextCenter loginMarginTop">
        <Spinner intent={intent} size={size} />
        <h3>{message}</h3>
      </div>
    );
  }
});
