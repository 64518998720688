export default {
  TOOL_DESCRIPTION:
    'Rivendell tool can be used to search devices based on different identifiers and send a push notification to that device',
  VESPA: 'VESPA',
  DDB: 'DDB',
  COMPARE_NOTE: 'Note: Source of Truth is VESPA',
  CUSTOM_APP_PAYLOAD: 'Custom App Payload',
  MESSAGE_TITLE_BODY: 'Message Title/Body',
  PAYLOAD_TYPE: 'payloadType'
};
