import React, { Component } from 'react';
import { getPermissionInfo, sendPermissionRequest } from '../../api/appApi';
import MultiSelectBox from '../common/MultiSelectBox';
import { MenuItem, Intent, FormGroup, Button, H3 } from '@blueprintjs/core';
import SimpleReactValidator from 'simple-react-validator';
import './Permission.css';

export default class Permission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permittedAppIds: [],
      unPermittedAppIds: [],
      selectedApps: [],
      disablePermissionRequest: false,
      loading: true
    };
    this.validator = new SimpleReactValidator();

    this.handleSubmit = this.handleSubmit.bind(this);

    this.renderAppSelect = this.renderAppSelect.bind(this);
    this.renderTag = this.renderTag.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.getSelectedIndex = this.getSelectedIndex.bind(this);
    this.isSelected = this.isSelected.bind(this);
    this.selectApp = this.selectApp.bind(this);
    this.deselect = this.deselect.bind(this);
    this.filterApps = this.filterApps.bind(this);
    this.handleTagRemove = this.handleTagRemove.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  handleSubmit() {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    sendPermissionRequest(this.state.selectedApps)
      .then(() => {
        this.props.showAlert(
          'Request sent! We will notifiy you on Slack once it gets approved.',
          Intent.SUCCESS
        );
      })
      .catch(error => this.props.showAlert(error.toString(), Intent.DANGER));

    this.setState(
      {
        disablePermissionRequest: true,
        selectedApps: []
      },
      function() {
        var self = this;
        setTimeout(function() {
          self.setState({
            disablePermissionRequest: false
          });
        }, 1000);
      }
    );
  }

  renderAppSelect = (app, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const keyStr = app.appId + '_' + app.providerType;
    const label = app.displayName ? '(' + app.displayName + ')' : '';
    return (
      <MenuItem
        active={modifiers.active}
        icon={this.isSelected(app) ? 'tick' : 'blank'}
        key={keyStr}
        label={label}
        onClick={handleClick}
        text={app.appId}
      />
    );
  };

  renderTag = app => app;

  handleSelect = app => {
    if (!this.isSelected(app)) {
      this.selectApp(app);
    } else {
      this.deselect(this.getSelectedIndex(app));
    }
  };

  getSelectedIndex(app) {
    return this.state.selectedApps.indexOf(app.appId);
  }

  isSelected(app) {
    return this.getSelectedIndex(app) !== -1;
  }

  selectApp(app) {
    var nextSelectedApps = this.state.selectedApps;
    nextSelectedApps.push(app.appId);
    this.setState({
      selectedApps: nextSelectedApps
    });
  }

  deselect(index) {
    const selected = this.state.selectedApps.filter((_app, i) => i !== index);
    this.setState({
      selectedApps: selected
    });
  }

  filterApps = (query, app) => {
    const appId = `${app.appId}`;
    const displayName = `${app.displayName}`;
    return (
      appId.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  };

  handleTagRemove = (_tag, index) => {
    this.deselect(index);
  };

  handleClear = () => this.setState({ selectedApps: [] });

  componentDidMount() {
    getPermissionInfo()
      .then(applications => {
        applications['permitted'].sort((a, b) => (a.appId > b.appId ? 1 : -1));
        applications['unPermitted'].sort((a, b) =>
          a.appId > b.appId ? 1 : -1
        );
        this.setState({
          permittedAppIds: applications['permitted'],
          unPermittedAppIds: applications['unPermitted'],
          loading: false
        });
      })
      .catch(error => {
        this.props.showAlert(error.toString(), Intent.DANGER);
      });
  }

  render() {
    const {
      unPermittedAppIds,
      selectedApps,
      permittedAppIds,
      disablePermissionRequest,
      loading
    } = this.state;
    const appList = permittedAppIds.map(app => (
      <li className="bp3-code-block" key={app.appId}>
        {' '}
        {app.appId}
        <span>{'(' + app.displayName + ')'}</span>
      </li>
    ));
    return (
      <div className="container">
        <div className="search-row">
          <div className="search-container">
            <div className="bp3-card bp3-elevation-2 full-width permissionCard">
              <H3 className="bp3-heading">Permitted Apps for you</H3>
              <ol className=".modifier .bp3-list">
                {loading && (
                  <div>
                    <li className="bp3-code-block bp3-skeleton"></li>
                    <li className="bp3-code-block bp3-skeleton"></li>
                    <li className="bp3-code-block bp3-skeleton"></li>
                  </div>
                )}
                {!loading && permittedAppIds.length === 0 && (
                  <li className="bp3-code-block">
                    There is no permitted Apps for you to publish.
                  </li>
                )}
                {!loading && permittedAppIds.length !== 0 && appList}
              </ol>
            </div>

            <div className="bp3-card bp3-elevation-2 full-width permissionCard">
              <H3>Publish Permission Request</H3>
              <div className="display-flex">
                <FormGroup
                  inline={false}
                  label=""
                  labelFor="text-input"
                  labelInfo="(required)"
                  className="appform"
                >
                  <MultiSelectBox
                    items={unPermittedAppIds}
                    itemRenderer={this.renderAppSelect}
                    tagRenderer={this.renderTag}
                    itemPredicate={this.filterApps}
                    onItemSelect={this.handleSelect}
                    handleTagRemove={this.handleTagRemove}
                    handleClear={this.handleClear}
                    selectedItems={selectedApps}
                    placeholder="Select applications"
                  />
                  {this.validator.message('list', selectedApps, 'min:1,array', {
                    className: 'note-alert-color'
                  })}
                </FormGroup>
              </div>
              <div>
                <Button
                  type="Submit"
                  intent="success"
                  text="Send Permission Request"
                  onClick={this.handleSubmit}
                  disabled={disablePermissionRequest}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
