import React, { Component } from 'react';
import Message from '../common/Message';
import { Dialog, Button, Classes } from '@blueprintjs/core';
import Constants from '../../utils/constants';
import SearchInfo from './SearchInfo';
import { anyInconsistencyPresentBetweenDdbAndVespaData } from '../../utils/helper';

function Compare(props) {
  const { vespa, dynamo } = props;
  var res = anyInconsistencyPresentBetweenDdbAndVespaData(vespa, dynamo);

  if (res) {
    if (res.length === 0) {
      return (
        <Message
          type="alert-success"
          message="Checked. The record is consistent in Vespa and Dynamo."
        />
      );
    } else {
      return (
        <Message
          type="alert-danger"
          message={'Inconsistent Field:  [ ' + res.join(', ') + ' ]'}
        />
      );
    }
  } else {
    return <Message type="alert-danger" message={'Information missing'} />;
  }
}

export default class CompareSearchModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }
  handleClose() {
    this.setState({ isOpen: false });
  }

  render() {
    const { vespa, dynamo } = this.props;
    return (
      <div className="full-width">
        <Button
          icon="comparison"
          className="bp3-large bp3-minimal bp3-fill"
          onClick={this.handleOpen}
          text="Compare"
        />
        <Dialog
          title="Raw Information"
          tabIndex={-1}
          icon="info-sign"
          onClose={this.handleClose}
          {...this.state}
          className="modal-full-width"
        >
          <div className={Classes.DIALOG_BODY}>
            <div className="modal-body">
              <Compare vespa={vespa} dynamo={dynamo} />
              <br />
              <div className="display-flex">
                <SearchInfo title="Vespa" content={vespa} />
                <SearchInfo title="Dynamo DB" content={dynamo} />
              </div>
            </div>
          </div>
          <div className={Classes.DIALOG_FOOTER}>
            <div className="display-flex">
              <span className="margin-auto note-alert-color">
                {Constants.COMPARE_NOTE}
              </span>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button onClick={this.handleClose}>Close</Button>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    );
  }
}
