function UUIDGenerator() {
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
    c
  ) {
    var r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

function anyInconsistencyPresentBetweenDdbAndVespaData(vespa, dynamo) {
  const fields = ['pushInfo', 'app', 'os'];
  var res = undefined;

  if (vespa && dynamo) {
    res = [];

    // compare subscriptions
    const vespasub = parsesub(vespa['subscriptions']);
    const dynamosub = parsesub(dynamo['subscriptions']);
    if (vespasub.join('') !== dynamosub.join('')) {
      res.push('subscriptions');
    }

    //compare deviceInfo
    if (vespa['deviceInfo']['model'] !== dynamo['deviceInfo']['model']) {
      res.push('deviceInfo_model');
    }

    //compare association
    const vespaAssociation = vespa['association'];
    const dynamoAssociation = dynamo['association'];
    if (vespaAssociation) {
      if (dynamoAssociation) {
        if (
          vespaAssociation['type'] !== dynamoAssociation['type'] ||
          vespaAssociation['value'] !== dynamoAssociation['value']
        ) {
          res.push('association');
        }
      } else {
        res.push('association');
      }
    }

    // compare other fields
    for (let i = 0; i < fields.length; i++) {
      if (
        JSON.stringify(
          vespa[fields[i]],
          Object.keys(vespa[fields[i]]).sort()
        ) !==
        JSON.stringify(dynamo[fields[i]], Object.keys(dynamo[fields[i]]).sort())
      ) {
        res.push(fields[i]);
      }
    }
  }
  return res;
}

function parsesub(data) {
  let res = [];
  if (data) {
    for (let i = 0; i < data.length; i++) {
      res.push(data[i]['tag']);
    }
  }
  return res.sort();
}

export { UUIDGenerator, anyInconsistencyPresentBetweenDdbAndVespaData };
