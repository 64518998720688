import React, { Component } from 'react';
import { Intent } from '@blueprintjs/core';
import RegisterPanel from './RegisterationPanel';
import './Register.css';
import config from '../../utils/default-config';
import Cookies from 'universal-cookie';
import { getAllApplications } from '../../api/appApi';

export default (class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');
    if (!accessToken) window.location.replace(config.appURL);
    getAllApplications()
      .then(applications => {
        applications.sort((a, b) => (a.appId > b.appId ? 1 : -1));
        this.setState({
          items: applications
        });
      })
      .catch(error => {
        this.props.showAlert(error.toString(), Intent.DANGER);
      });
  }

  render() {
    const { items } = this.state;
    return <RegisterPanel id="registerPanel" items={items} />;
  }
});
