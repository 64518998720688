function getEnvironment() {
  if (
    window.location.origin.indexOf('https://tool-dev.push.ouryahoo.com') > -1 ||
    window.location.origin.indexOf('http://localhost:3000') > -1
  ) {
    return 'dev';
  } else if (
    window.location.origin.indexOf('https://tool-dev-int.push.ouryahoo.com') >
    -1
  ) {
    return 'integration';
  } else if (
    window.location.origin.indexOf('https://tool.push.ouryahoo.com') > -1
  ) {
    return 'production';
  }
}

var environmentConfig = {
  dev: {
    apiURL: 'https://localhost:8443/v1',
    clientId: '47b1ac6d-c494-41df-82ea-d645cced7990',
    redirectURL: 'http://localhost:3000/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: false,
    environment: 'Development'
  },
  integration: {
    apiURL: 'https://dev-int-api.push.yahoo.com/v1',
    publishURL: 'https://dev-int-publisher.push.yahoo.com/v1',
    clientId: '50298686-4758-4d35-bf7d-d7ef155c8375',
    redirectURL: 'https://tool-dev-int.push.ouryahoo.com/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: false,
    environment: 'Integration'
  },
  production: {
    apiURL: 'https://api.push.yahoo.com/v1',
    publishURL: 'https://publisher.push.yahoo.com/v1',
    clientId: '8c4297c0-1c4a-48dc-ad99-c8786daac32f',
    redirectURL: 'https://tool.push.ouryahoo.com/login',
    appURL: window.location.origin,
    dashboardURL: window.location.origin + '/search',
    aasAuthenticatorURL:
      'https://identity.publishing.oath.com/oauth/38ddc0e1-d1f6-441a-a58c-486ded9bc63f/authorize?',
    isProduction: true,
    environment: 'Production'
  }
};

export default environmentConfig[getEnvironment()];
