import axios from 'axios';
import Cookies from 'universal-cookie';
import config from '../utils/default-config';
import { ErrorHandler } from '../components/common/Common';

export function getPermittedApplications() {
  return getURL('/permittedappids');
}

export function getPermissionInfo() {
  return getURL('/permissioninfo');
}

export function getAllApplications() {
  return getURL('/appids');
}

function getURL(url) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  var headers = {
    Authorization: 'Bearer ' + accessToken
  };
  var payload = {};

  return axios({
    url: config.apiURL + '/tool' + url,
    method: 'GET',
    headers: headers,
    data: payload
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}

export function sendPermissionRequest(appList) {
  const cookies = new Cookies();
  const accessToken = cookies.get('accessToken');
  var headers = {
    Authorization: 'Bearer ' + accessToken
  };
  var payload = appList;

  return axios({
    url: config.apiURL + '/tool/requestpermission',
    method: 'POST',
    headers: headers,
    data: payload
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}
