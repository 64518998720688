import React, { Component } from 'react';
import { Spinner, H5 } from '@blueprintjs/core';
import SearchResultItem from './SearchResultItem';
import Message from '../common/Message';
import Constants from '../../utils/constants';
import { anyInconsistencyPresentBetweenDdbAndVespaData } from '../../utils/helper';

class SearchResultContainer extends Component {
  searchVespaObject = dynamoItem => {
    const { searchResult } = this.props;
    const vespa = searchResult[Constants.VESPA]['registrations'];
    let vespaResult = {};
    if (dynamoItem.association) {
      vespaResult = vespa.find(function(ele) {
        return (
          ele.association &&
          ele.association.type === dynamoItem.association.type &&
          ele.association.value === dynamoItem.association.value &&
          ele.registrationId === dynamoItem.registrationId
        );
      });
    } else {
      vespaResult = vespa.find(function(ele) {
        return (
          !ele.association && ele.registrationId === dynamoItem.registrationId
        );
      });
    }
    return vespaResult;
  };

  render() {
    const { searchResult, msgType, error } = this.props;
    let result;
    switch (msgType) {
      case 'data': {
        var dynamo = searchResult[Constants.DDB]['registrations'];
        var isConsistent = true;

        result = dynamo.map((item, index) => {
          var res = anyInconsistencyPresentBetweenDdbAndVespaData(
            this.searchVespaObject(item),
            item
          );
          isConsistent = isConsistent && res && res.length === 0;
          return (
            <SearchResultItem
              key={index}
              index={index}
              vespa={this.searchVespaObject(item)}
              dynamo={item}
            />
          );
        });
        break;
      }
      case 'generalError':
        result = <Message type="alert-danger" message={error} />;
        break;
      case 'errorCode':
        let detail = (
          <div>
            <span>{error}. Check error code detail in this </span>
            <a href="https://git.ouroath.com/rivendell/rivendell/blob/master/common/src/main/java/com/oath/rivendell/exception/RivendellErrorCode.java">
              Doc
            </a>
            <span>.</span>
          </div>
        );
        result = <Message type="alert-danger" message={detail} />;
        break;
      case 'searching':
        result = <Spinner size={50} />;
        break;
      default:
        result = '';
    }

    return (
      <div>
        {result && <H5>Search Results</H5>}
        {result}
        {result && !isConsistent && (
          <Message
            type="note-alert-color"
            message={'Inconsistency present in data between Vespa and DynamoDB'}
          />
        )}
      </div>
    );
  }
}

export default SearchResultContainer;
