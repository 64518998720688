import React, { Component } from 'react';
import { MultiSelect } from '@blueprintjs/select';
import { MenuItem, Button, Intent } from '@blueprintjs/core';
import './Common.css';

export default (class MultiSelectBox extends Component {
  render() {
    const INTENTS = [
      Intent.NONE,
      Intent.PRIMARY,
      Intent.SUCCESS,
      Intent.DANGER,
      Intent.WARNING
    ];
    const getTagProps = (_value, index) => ({
      intent: INTENTS[index % INTENTS.length],
      minimal: true
    });
    const {
      items,
      itemRenderer,
      tagRenderer,
      onItemSelect,
      itemPredicate,
      handleTagRemove,
      handleClear,
      selectedItems,
      placeholder
    } = this.props;
    const clearButton =
      selectedItems.length > 0 ? (
        <Button icon="cross" minimal={true} onClick={handleClear} />
      ) : null;
    return (
      <MultiSelect
        items={items}
        itemRenderer={itemRenderer}
        placeholder={placeholder}
        tagRenderer={tagRenderer}
        onItemSelect={onItemSelect}
        itemPredicate={itemPredicate}
        tagInputProps={{
          tagProps: getTagProps,
          onRemove: handleTagRemove,
          rightElement: clearButton
        }}
        selectedItems={selectedItems}
        fill={true}
        resetOnSelect={true}
        allowCreate={false}
        popoverProps={{ minimal: true, popoverClassName: 'popoverList' }}
        noResults={<MenuItem disabled={true} text="No results." />}
      />
    );
  }
});
