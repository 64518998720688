import React, { Component } from 'react';
import { Card, FormGroup } from '@blueprintjs/core';
import InputField from '../common/InputField';
import {
  IdentifierType,
  NotificationForm,
  DialogContainer,
  MessageContainer,
  ButtonContainer,
  publishPayload,
  payloadTemplate,
  ErrorContainer
} from './PublishCommon';
import './Publish.css';
import InputTextArea from '../common/InputTextArea';
import config from '../../utils/default-config';
import MultiSelectBox from '../common/MultiSelectBox';
import SimpleReactValidator from 'simple-react-validator';

class EndpointContainer extends Component {
  render() {
    const {
      notificationId,
      identifierType,
      identifierValue,
      onChange,
      items,
      itemRenderer,
      tagRenderer,
      itemPredicate,
      onItemSelect,
      handleTagRemove,
      handleClear,
      selected,
      validator
    } = this.props;

    const selectedList = selected.map(app => app.appId);

    const placeholder = 'Seperated by comma';
    return (
      <div>
        <NotificationForm
          name="notificationId"
          displayName="Notification Id"
          mutemsg="required"
          value={notificationId}
          onChange={onChange}
          validator={validator}
        />

        <FormGroup>
          <label className="bp3-label .modifier">
            Application Id
            <span className="bp3-text-muted">(required)</span>
          </label>
          <MultiSelectBox
            items={items}
            itemRenderer={itemRenderer}
            tagRenderer={tagRenderer}
            itemPredicate={itemPredicate}
            onItemSelect={onItemSelect}
            handleTagRemove={handleTagRemove}
            handleClear={handleClear}
            selectedItems={selectedList}
            placeholder="Select applications"
          />
          {validator.message('app', selectedList, 'min:1,array', {
            className: 'note-alert-color'
          })}
        </FormGroup>

        <InputField
          id="tag"
          name="tag"
          displayName="Tag"
          value={this.props.tag}
          onChange={onChange}
          required={true}
          validator={validator}
        />

        <IdentifierType
          name="identifierType"
          mutemsg={config.isProduction ? 'required' : 'optional'}
          displayName="Identifier Type"
          value={identifierType}
          onChange={onChange}
          panel="tag"
        />

        <InputTextArea
          id="identifierValue"
          name="identifierValue"
          displayName="Identifier Value"
          mutemsg={
            (config.isProduction ? 'required' : 'optional') + ' - max 100'
          }
          panel="tag"
          placeholder={placeholder}
          value={identifierValue}
          onChange={onChange}
          required={config.isProduction}
        />
      </div>
    );
  }
}

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      error: ''
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.payloadFormat = this.payloadFormat.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  payloadFormat(params) {
    var { template, identifierType, identifierValue } = payloadTemplate(params);

    if (identifierValue.length === 1 && identifierValue[0] === '') {
      template['query'] = {
        EQ: ['tag', params.tag]
      };
    } else if (identifierValue.length === 1) {
      template['query'] = {
        AND: [
          {
            EQ: ['tag', params.tag]
          },
          {
            EQ: [identifierType, identifierValue[0]]
          }
        ]
      };
    } else {
      template['query'] = {
        AND: [
          {
            EQ: ['tag', params.tag]
          },
          {
            OR: []
          }
        ]
      };
      identifierValue.forEach(item => {
        template['query']['AND'][1]['OR'].push({
          EQ: [identifierType, item]
        });
      });
    }

    return template;
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleSubmit() {
    const { common, selected } = this.props;
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (common.identifierValue.split(',').length > 100) {
      this.setState({
        error: 'Maximum 100 items can be added in identifier value'
      });
      return;
    } else {
      this.setState({
        error: ''
      });
    }

    const param = {
      payload: this.payloadFormat(common)
    };

    var store = {
      selectedApps: selected,
      body: common.body,
      identifierType: common.identifierType,
      identifierValue: common.identifierValue,
      notificationId: common.notificationId,
      selectedTabId: common.selectedTabId,
      tag: common.tag,
      title: common.title
    };

    publishPayload(param, store, this.props);
  }

  render() {
    const {
      common,
      onChange,
      itemRenderer,
      tagRenderer,
      itemPredicate,
      onItemSelect,
      handleTagRemove,
      handleClear,
      items,
      selected
    } = this.props;
    const { error } = this.state;
    const payload = JSON.stringify(this.payloadFormat(common), null, '\t');
    this.validator.purgeFields();

    return (
      <div>
        <EndpointContainer
          notificationId={common.notificationId}
          tag={common.tag}
          identifierType={common.identifierType}
          identifierValue={common.identifierValue}
          onChange={onChange}
          itemRenderer={itemRenderer}
          tagRenderer={tagRenderer}
          itemPredicate={itemPredicate}
          onItemSelect={onItemSelect}
          handleTagRemove={handleTagRemove}
          handleClear={handleClear}
          items={items}
          selected={selected}
          validator={this.validator}
        />
        <MessageContainer
          title={common.title}
          body={common.body}
          onChange={onChange}
          validator={this.validator}
        />
        <ButtonContainer
          onSubmit={this.handleSubmit}
          onOpen={this.handleOpen}
        />
        <DialogContainer
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          value={payload}
        />
        <ErrorContainer error={error} />
      </div>
    );
  }
}

export default class TagPanel extends Component {
  render() {
    return (
      <Card id={this.props.id} className="tabPanel borderRadius" elevation={1}>
        <FormContainer {...this.props} />
      </Card>
    );
  }
}
