import React, { Component } from 'react';
import { AnchorButton } from '@blueprintjs/core';
import './App.css';
import config from '../../utils/default-config';
import Constants from '../../utils/constants';
import Cookies from 'universal-cookie';

class App extends Component {
  constructor(props) {
    super(props);
    this.getAASAuthenticatorParams = this.getAASAuthenticatorParams.bind(this);
    this.generateState = this.generateState.bind(this);
  }

  generateState() {
    var state =
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15);
    localStorage.setItem('state', state);
    return state;
  }

  getAASAuthenticatorParams() {
    var urlParameters = {
      response_type: 'code',
      client_id: config.clientId,
      redirect_uri: config.redirectURL,
      scope: 'athena openid',
      state: this.generateState()
    };
    var queryString = Object.keys(urlParameters)
      .map(
        key =>
          encodeURIComponent(key) + '=' + encodeURIComponent(urlParameters[key])
      )
      .join('&');
    return queryString;
  }

  componentWillMount() {
    const cookies = new Cookies();
    const accessToken = cookies.get('accessToken');
    if (accessToken) {
      window.location.replace(config.dashboardURL);
    }
  }

  render() {
    return (
      <div className="appTextCenter">
        <h2 className="bp3-heading appMarginTop">Sign in to Rivendell Tool</h2>
        <div className="bp3-text-large">
          <span>{Constants.TOOL_DESCRIPTION}</span>
        </div>
        <br />
        <div>
          <AnchorButton
            icon="user"
            text="Click here to login with Shield"
            href={config.aasAuthenticatorURL + this.getAASAuthenticatorParams()}
            className="bp3-intent-primary"
          />
        </div>
      </div>
    );
  }
}

export default App;
