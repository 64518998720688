import React, { Component } from 'react';
import { Card, FormGroup } from '@blueprintjs/core';
import {
  IdentifierType,
  ButtonContainer,
  NotificationForm,
  DialogContainer,
  MessageContainer,
  publishPayload,
  payloadTemplate,
  ErrorContainer,
  PayloadType
} from './PublishCommon';
import InputTextArea from '../common/InputTextArea';
import './Publish.css';
import MultiSelectBox from '../common/MultiSelectBox';
import SimpleReactValidator from 'simple-react-validator';

class EndpointContainer extends Component {
  render() {
    const placeholder = 'Seperated by comma';
    const {
      notificationId,
      identifierType,
      identifierValue,
      onChange,
      items,
      itemRenderer,
      tagRenderer,
      itemPredicate,
      onItemSelect,
      handleTagRemove,
      handleClear,
      selected,
      validator
    } = this.props;
    const selectedList = selected.map(app => app.appId);
    return (
      <div>
        <NotificationForm
          name="notificationId"
          displayName="Notification Id"
          mutemsg="required"
          value={notificationId}
          onChange={onChange}
          validator={validator}
        />

        <FormGroup>
          <label className="bp3-label .modifier">
            Application Id
            <span className="bp3-text-muted">(required)</span>
          </label>
          <MultiSelectBox
            items={items}
            itemRenderer={itemRenderer}
            tagRenderer={tagRenderer}
            itemPredicate={itemPredicate}
            onItemSelect={onItemSelect}
            handleTagRemove={handleTagRemove}
            handleClear={handleClear}
            selectedItems={selectedList}
            placeholder="Select applications"
          />
          {validator.message('app', selectedList, 'min:1,array', {
            className: 'note-alert-color'
          })}
        </FormGroup>

        <IdentifierType
          name="identifierType"
          displayName="Identifier Type"
          mutemsg="required"
          value={identifierType}
          onChange={onChange}
        />

        <InputTextArea
          id="identifierValue"
          name="identifierValue"
          displayName="Identifier Value"
          mutemsg="required - max 100"
          placeholder={placeholder}
          value={identifierValue}
          onChange={onChange}
          validator={validator}
        />
      </div>
    );
  }
}

class FormContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      error: ''
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.payloadFormat = this.payloadFormat.bind(this);
    this.parseCustomAppPayload = this.parseCustomAppPayload.bind(this);

    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  payloadFormat(params) {
    this.parseCustomAppPayload({ showError: false });
    var { template, identifierType, identifierValue } = payloadTemplate(params);

    if (identifierValue.length === 1) {
      template['query'] = {
        EQ: [identifierType, identifierValue[0]]
      };
    } else {
      template['query'] = {
        OR: []
      };
      identifierValue.forEach(item => {
        template['query']['OR'].push({
          EQ: [identifierType, item]
        });
      });
    }
    return template;
  }

  handleOpen() {
    this.setState({ isOpen: true });
  }

  handleClose() {
    this.setState({ isOpen: false });
  }

  handleSubmit() {
    const { common, selected } = this.props;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    if (common.identifierValue.split(',').length > 100) {
      this.setState({
        error: 'Maximum 100 items can be added in identifier value'
      });
      return;
    }

    this.setState({
      error: ''
    });

    this.parseCustomAppPayload({ showError: true });
    let param = { payload: this.payloadFormat(common) };

    var store = {
      selectedApps: selected,
      body: common.body,
      identifierType: common.identifierType,
      identifierValue: common.identifierValue,
      notificationId: common.notificationId,
      selectedTabId: common.selectedTabId,
      title: common.title
    };

    publishPayload(param, store, this.props);
  }

  parseCustomAppPayload(params) {
    const { common } = this.props;
    try {
      if (common.customAppPayload && common.customAppPayload !== '') {
        common.customAppPayloadJson = JSON.parse(common.customAppPayload);
      }
    } catch (e) {
      if (params.showError) {
        this.setState({
          error: 'Parse Error: invalid json.'
        });
        return;
      }
      common.customAppPayloadJson = common.customAppPayload;
      return;
    }
  }

  render() {
    const {
      common,
      onChange,
      itemRenderer,
      tagRenderer,
      itemPredicate,
      onItemSelect,
      handleTagRemove,
      handleClear,
      items,
      selected
    } = this.props;
    const { error } = this.state;
    const payload = JSON.stringify(this.payloadFormat(common), null, '\t');
    this.validator.purgeFields();
    let appPayloadContainer;
    if (common.isCustomPayloadSelected) {
      appPayloadContainer = (
        <InputTextArea
          id="customAppPayload"
          name="customAppPayload"
          displayName="Custom App Payload"
          value={common.customAppPayload}
          onChange={onChange}
          required={true}
          validator={this.validator}
        />
      );
    } else {
      appPayloadContainer = (
        <MessageContainer
          title={common.title}
          body={common.body}
          onChange={onChange}
          validator={this.validator}
        />
      );
    }
    return (
      <div>
        <EndpointContainer
          notificationId={common.notificationId}
          identifierType={common.identifierType}
          identifierValue={common.identifierValue}
          onChange={onChange}
          itemRenderer={itemRenderer}
          tagRenderer={tagRenderer}
          itemPredicate={itemPredicate}
          onItemSelect={onItemSelect}
          handleTagRemove={handleTagRemove}
          handleClear={handleClear}
          items={items}
          selected={selected}
          validator={this.validator}
        />
        <PayloadType
          name="payloadType"
          displayName="Payload Type"
          mutemsg="required"
          value={common.payloadType}
          onChange={onChange}
        />
        {appPayloadContainer}
        <ButtonContainer
          onSubmit={this.handleSubmit}
          onOpen={this.handleOpen}
        />
        <DialogContainer
          isOpen={this.state.isOpen}
          onClose={this.handleClose}
          value={payload}
        />
        <ErrorContainer error={error} />
      </div>
    );
  }
}

export default class TargetPanel extends Component {
  render() {
    return (
      <Card id={this.props.id} className="tabPanel borderRadius" elevation={1}>
        <FormContainer {...this.props} />
      </Card>
    );
  }
}
