import React, { Component } from 'react';
import { submitCountRequest } from '../../api';
import InputField from '../common/InputField.jsx';
import { Button, FormGroup, MenuItem, Intent, H4, H5 } from '@blueprintjs/core';
//import './Count.css';
import SimpleReactValidator from 'simple-react-validator';
import MultiSelectBox from '../common/MultiSelectBox.jsx';
import { getAllApplications } from '../../api/appApi';

class CountForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appIds: [],
      allAppIds: [],
      tag: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleResultChange = this.handleResultChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.setupLink = this.setupLink.bind(this);
    this.handleAppSelectAdd = this.handleAppSelectAdd.bind(this);
    this.handleTagRemove = this.handleTagRemove.bind(this);
    this.handleClear = this.handleClear.bind(this);

    // maybe functions for the app select box?

    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  setupLink() {
    const { appIds, tag } = this.props.params;
    if (appIds && tag) {
      this.setState(
        {
          appIds: appIds,
          tag: tag
        },
        this.handleSubmit
      );
    }
  }

  componentDidMount() {
    getAllApplications()
      .then(applications => {
        applications.sort((a, b) => (a.appId > b.appId ? 1 : -1));
        this.setState(
          {
            allAppIds: applications
          },
          this.setupLink
        );
      })
      .catch(error => {
        this.props.showAlert(error.toString(), Intent.DANGER);
      });
  }

  handleResultChange(error, msgType, counts, callback) {
    this.props.onChange(error, msgType, [], counts, callback);
  }

  handleChange(name, value) {
    this.setState({
      [name]: value
    });
  }

  handleSelectChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleSubmit() {
    const { appIds, tag } = this.state;

    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }

    const appIdNames = appIds.map(app => {
      return app.appId;
    });

    const param = {
      appIds: appIdNames,
      tag: tag
    };

    this.handleResultChange(undefined, 'searching', [], () => {
      submitCountRequest(param)
        .then(data => {
          try {
            if (data && data['counts'].length !== 0) {
              this.handleResultChange(undefined, 'counts', data['counts']);
            } else {
              this.handleResultChange(
                "No Counts found. Please check the app id's or the tag.",
                'generalError',
                []
              );
            }
          } catch (error) {
            this.handleResultChange('Server Error.', 'generalError', []);
          }
        })
        .catch(error => {
          this.props.showAlert(error.toString(), Intent.DANGER);
          try {
            var errCode = error.response.data.errorCode
              ? error.response.data.errorCode
              : 'Unknown';
            this.handleResultChange(
              'Error code: ' + errCode.toString(),
              'errorCode',
              []
            );
          } catch (error) {
            this.handleResultChange(
              'Unknown Error. Check Internet connection first.',
              'generalError',
              []
            );
          }
        });
    });
    let urlStr = '/search/count/' + tag + '?appIds=';
    appIdNames.forEach(appId => {
      urlStr += appId + ',';
    });
    urlStr = urlStr.substring(0, urlStr.length - 1);
    window.history.pushState({}, '', urlStr);
  }

  handleAppSelectAdd(app) {
    const currentAppIds = this.state.appIds;
    if (!currentAppIds.includes(app)) {
      this.setState({
        appIds: currentAppIds.concat(app)
      });
    }
  }

  renderAppSelect = (app, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const keyStr = app.appId + '-' + app.providerType;
    const label = app.displayName ? '(' + app.displayName + ')' : '';
    return (
      <MenuItem
        active={modifiers.active}
        key={keyStr}
        label={label}
        onClick={handleClick}
        text={app.appId}
      />
    );
  };

  filterApps = (query, app) => {
    const appId = `${app.appId}`;
    const displayName = `${app.displayName}`;
    return (
      appId.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  };

  handleTagRemove = (tag, index) => {
    const splitTag = tag.split('-');
    const appIdName = splitTag[0];
    const provider = splitTag[1];
    this.setState({
      appIds: this.state.appIds.filter(app => {
        return !(app.appId === appIdName && app.providerType === provider);
      })
    });
  };

  handleClear = () => {
    this.setState({
      appIds: []
    });
  };

  renderTag = app => {
    return app.appId + '-' + app.providerType;
  };

  render() {
    const { allAppIds, tag, appIds } = this.state;
    this.validator.purgeFields();
    console.log('does it hang here?');
    console.log('allAppIds: ' + allAppIds);
    console.log('appIds: ' + appIds);
    console.log('tag: ' + tag);
    return (
      <div className="bp3-card bp3-elevation-2 full-width">
        <H4>Subscribers Count</H4>
        <div className="bp3-card bp3-elevation-2 full-width">
          <div className="display-flex">
            <FormGroup
              inline={false}
              label="App IDs"
              labelFor="text-input"
              labelInfo="(required)"
              className="margin-right"
            >
              <MultiSelectBox
                items={allAppIds}
                itemRenderer={this.renderAppSelect}
                tagRenderer={this.renderTag}
                onItemSelect={this.handleAppSelectAdd}
                itemPredicate={this.filterApps}
                handleTagRemove={this.handleTagRemove}
                handleClear={this.handleClear}
                selectedItems={appIds}
                placeholder="Multi-select"
              />
              {this.validator.message('applications', appIds, 'required', {
                className: 'note-alert-color'
              })}
            </FormGroup>
            <div className="inputField">
              <InputField
                name="tag"
                value={tag}
                onChange={this.handleChange}
                displayName="Tag"
                id="tag"
                validator={this.validator}
                placeholder="Input one tag"
              />
            </div>
          </div>
          <Button
            type="Submit"
            intent="success"
            text="Count"
            className="margin-auto"
            onClick={this.handleSubmit}
          />
        </div>
        <br />
        <H5>
          Note: These counts only reflect a max value, actual delivery numbers
          are based on other factors like query and dedupe.
        </H5>
      </div>
    );
  }
}

class CountFormContainer extends Component {
  render() {
    return <CountForm {...this.props} />;
  }
}

export default CountFormContainer;
