import axios from 'axios';
import config from '../utils/default-config';
import { ErrorHandler } from '../components/common/Common';

export default function submitRegRequest(ptVal, pTypeVal, appId) {
  return axios({
    url: config.apiURL + '/registration',
    method: 'POST',
    data: {
      app: {
        id: appId
      },

      pushInfo: {
        pushToken: ptVal,
        pushService: pTypeVal
      }
    }
  })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      ErrorHandler(error);
      throw error;
    });
}
