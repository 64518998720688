import React, { Component } from 'react';
import {
  Button,
  Spinner,
  Divider,
  H4,
  Card,
  Tag,
  Intent
} from '@blueprintjs/core';

export default (class PublishResultContainer extends Component {
  constructor(props) {
    super(props);
    this.handleClear = this.handleClear.bind(this);
    this.buildResult = this.buildResult.bind(this);
  }

  handleClear() {
    localStorage.setItem('pushHistory', '[]');
    this.props.handleResultChange(false);
  }

  buildResult() {
    let detail = (
      <div>
        <span> Check errorCode in this </span>
        <a
          href="https://git.ouroath.com/rivendell/rivendell/blob/master/common/src/main/java/com/oath/rivendell/exception/RivendellErrorCode.java"
          target="_blank"
          rel="noopener noreferrer"
        >
          Doc
        </a>
        <span>.</span>
      </div>
    );

    var results = this.props.results;
    var listItems = [];

    results.forEach((item, index) => {
      const { res } = item;
      var failure = !res.includes('notificationId');
      const status = res.includes('notificationId') ? 'Success' : 'Failure';
      const intent = res.includes('notificationId')
        ? Intent.SUCCESS
        : Intent.DANGER;
      const tag = <Tag intent={intent}>{status}</Tag>;
      listItems.push(
        <div key={index}>
          <Button
            id="editButton"
            className="rightAlign"
            icon="edit"
            small={true}
            type="button"
            minimal={true}
            onClick={() => this.props.handleEdit(item)}
          />
          <div>
            <div
              className={failure ? 'note-alert-color' : 'note-success-color'}
            >
              {tag}
            </div>
            <div index={index}>{res}</div>
            <div>{res.includes('notificationId') ? '' : detail}</div>
          </div>
          <br />
          <br />
          <Divider />
          <br />
        </div>
      );
    });
    return listItems;
  }

  render() {
    return (
      <Card id="resultCardContainer" className="borderRadius" elevation={1}>
        <div id="resultcontainer">
          <div id="resultHeader">
            <H4 id="h4text">Result</H4>
            <Button
              id="clearButton"
              className="rightAlign"
              icon="trash"
              small={true}
              type="button"
              minimal={true}
              onClick={this.handleClear}
            />
          </div>
          {this.props.loading ? <Spinner size={50} /> : ''}
          {this.buildResult().reverse()}
        </div>
      </Card>
    );
  }
});
