import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Search from './components/search/Search';
import Register from './components/register/Register';
import App from './components/app/App';
import Login from './components/login/Login';
import * as serviceWorker from './serviceWorker';
import Header from './components/common/Header';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Toaster, Position } from '@blueprintjs/core';
import Publish from './components/publish/Publish';
import Permission from './components/permission/Permission';

class Container extends Component {
  constructor(props) {
    super(props);
    this.toaster = React.createRef();
  }

  showAlert = (msg, intent) => {
    this.toaster.current.show({ message: msg, intent: intent });
  };

  render() {
    return (
      <div>
        <Toaster position={Position.TOP} ref={this.toaster} />
        <Router>
          <Header brand="Rivendell Tool" />
          <Route exact path="/" component={App} showAlert />
          <Route path="/login" component={Login} />
          <Route
            exact
            path="/search"
            render={routeProps => (
              <Search {...routeProps} showAlert={this.showAlert} />
            )}
          />
          <Route
            exact
            path="/register"
            render={routeProps => (
              <Register {...routeProps} showAlert={this.showAlert} />
            )}
          />
          <Route
            exact
            path="/search/:identifierType/:appId/:appDisplayName/:identifierValue"
            render={routeProps => (
              <Search {...routeProps} showAlert={this.showAlert} />
            )}
          />
          <Route
            exact
            path="/search/:identifierType/:associationType/:appId/:appDisplayName/:identifierValue"
            render={routeProps => (
              <Search {...routeProps} showAlert={this.showAlert} />
            )}
          />
          <Route
            exact
            path="/publish"
            render={routeProps => (
              <Publish {...routeProps} showAlert={this.showAlert} />
            )}
          />
          <Route
            exact
            path="/permission"
            render={routeProps => (
              <Permission {...routeProps} showAlert={this.showAlert} />
            )}
          />
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<Container />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
