import React, { Component } from 'react';
import { Card, FormGroup } from '@blueprintjs/core';
import { Button, HTMLSelect, MenuItem, Spinner } from '@blueprintjs/core';
import InputTextArea from '../common/InputTextArea';
import SelectBox from '../common/SelectBox';
import './Register.css';
import SimpleReactValidator from 'simple-react-validator';
import submitRegRequest from '../../api/registrationApi';

class RegisterResultBox extends React.Component {
  render() {
    const { value } = this.props;
    return <div>{value}</div>;
  }
}
class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
      pTypeVal: 'FCM',
      regIdVal: '',
      render: '',
      loading: '',
      identifierValue: '',
      app: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleAppSelectChange = this.handleAppSelectChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validator = new SimpleReactValidator({
      element: (message, className) => (
        <div className={className}>{message}</div>
      )
    });
  }

  handleChange(event) {
    this.setState({ pTypeVal: event.target.value });
  }
  onChange(name, value) {
    this.setState({ ptVal: value, identifierValue: value });
  }
  handleAppSelectChange(app) {
    this.setState({
      app
    });
  }
  _renderSubComp() {
    if (this.state.render) {
      return <RegisterResultBox value={this.state.regIdVal} />;
    }
  }
  handleSubmit(event) {
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    var date = new Date().toLocaleTimeString();
    var res = '';
    this.setState({ loading: true });
    submitRegRequest(
      this.state.ptVal,
      this.state.pTypeVal,
      this.state.app.appId
    )
      .then(data => {
        res = `[${date}] ${JSON.stringify(data)}.`;
        this.setState({
          regIdVal: 'Registration ID: ' + data.registrationId,
          render: true,
          loading: false
        });
      })
      .catch(error => {
        try {
          res = `[${date}] ${error.toString()}. ${JSON.stringify(
            error.response.data,
            null,
            '\t'
          )}.`;
          this.setState({ regIdVal: res, render: true, loading: false });
        } catch (err) {
          res = `[${date}] ${error.toString()}`;
        }
        console.error(res);
      });
    event.preventDefault();
  }
  renderAppSelect = (app, { handleClick, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    const keyStr = app.appId + '_' + app.providerType;
    const label = app.displayName ? '(' + app.displayName + ')' : '';
    return (
      <MenuItem
        active={modifiers.active}
        key={keyStr}
        label={label}
        onClick={handleClick}
        text={app.appId}
      />
    );
  };

  filterApps = (query, app) => {
    const appId = `${app.appId}`;
    const displayName = `${app.displayName}`;
    return (
      appId.toLowerCase().indexOf(query.toLowerCase()) >= 0 ||
      displayName.toLowerCase().indexOf(query.toLowerCase()) >= 0
    );
  };
  render() {
    const { items } = this.props;
    const { identifierValue, app } = this.state;
    this.validator.purgeFields();
    return (
      <div>
        <div>
          <FormGroup>
            <label className="bp3-label .modifier">
              Application Id
              <span className="bp3-text-muted">(required)</span>
            </label>
            <SelectBox
              items={items}
              onItemSelect={this.handleAppSelectChange}
              itemRenderer={this.renderAppSelect}
              selectedItem={app}
              name="displayName"
              label="appId"
              defaultLabel="Select Application"
              itemPredicate={this.filterApps}
            />
            {this.validator.message('application', app, 'required', {
              className: 'note-alert-color'
            })}
          </FormGroup>
          <FormGroup>
            <label className="bp3-label .modifier">Push Service</label>
            <HTMLSelect
              options={['FCM', 'APNS']}
              onChange={this.handleChange}
            />
          </FormGroup>

          <InputTextArea
            id="pushToken"
            name="pushToken"
            mutemsg="Required"
            displayName="Push Token"
            value={identifierValue}
            onChange={this.onChange}
            validator={this.validator}
          />
        </div>
        <Button
          text="Register"
          intent="success"
          className="margin-auto"
          type="Submit"
          onClick={this.handleSubmit}
        />
        <p id="regId"> {this._renderSubComp()} </p>
        {this.state.loading ? <Spinner size={50} /> : ''}
      </div>
    );
  }
}
export default class RegisterPanel extends Component {
  render() {
    return (
      <Card id={this.props.id} elevation={1}>
        <RegistrationForm {...this.props} />
      </Card>
    );
  }
}
