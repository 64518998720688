import React, { Component } from 'react';
import { Spinner, H5, Card } from '@blueprintjs/core';
//import CountResultItem from './CountResultItem';
import Message from '../common/Message';

class CountResultContainer extends Component {
  render() {
    const { counts, msgType, error } = this.props;
    let result;
    switch (msgType) {
      case 'counts':
        result = counts.map(countRecord => (
          <Card elevation="2" className="result-col">
            <h3>App:</h3>
            <p>{countRecord['appId']}</p>
            <h3>Subscribers Count:</h3>
            <p>{countRecord['number']}</p>
          </Card>
        ));
        break;
      case 'generalError':
        result = <Message type="alert-danger" message={error} />;
        break;
      case 'errorCode':
        let detail = (
          <div>
            <span>{error}. Check error code detail in this </span>
            <a href="https://git.ouroath.com/rivendell/rivendell/blob/master/common/src/main/java/com/oath/rivendell/exception/RivendellErrorCode.java">
              Doc
            </a>
            <span>.</span>
          </div>
        );
        result = <Message type="alert-danger" message={detail} />;
        break;
      case 'searching':
        result = <Spinner size={50} />;
        break;
      default:
        result = '';
    }

    return (
      <div>
        {result && <H5>Search Results</H5>}
        <div className="details display-flex max-height-25">{result}</div>
      </div>
    );
  }
}

export default CountResultContainer;
